<template>
  <div class="application-public">
    <div class="left">
      <div class="title">公共信息</div>
      <div class="box">
        <el-form ref="addForm" :rules="rules" :model="addForm" size="small">
          <el-form-item prop="jobName" label="作业名称" label-width="6em">
            <el-input v-model="addForm.jobName" placeholder="请填写作业名称" />
          </el-form-item>
          <el-form-item prop="dir" label="数据目录" label-width="6em">
            <el-input
              v-model="addForm.dir"
              readonly
              style="cursor:pointer"
              placeholder="点击选择数据目录"
              @click="changDirs"
            />
          </el-form-item>
          <el-form-item v-if="iscpu == 1" prop="tasks_per_node" label="每节点核心数" label-width="8em">
            <el-input-number v-model="addForm.tasks_per_node" :min="1" :max="56" :step="1" style="z-index:0" />
            <div v-if="iscpu == 1" class="tips">
              *每节点为56核心
            </div>
          </el-form-item>
          <el-form-item v-if="iscpu == 1" prop="nodes" label="节点数" label-width="8em">
            <el-input-number v-model="addForm.nodes" :min="1" :max="maxNodes" :step="1" style="z-index:0" />
          </el-form-item>
          <el-form-item v-if="iscpu == 0" prop="gpu" label="GPU" label-width="6em">
            <el-input-number v-model="addForm.gpu" :min="1" :step="1" style="z-index:0" />
          </el-form-item>
          <el-form-item prop="lateTime" label="最大运行时长" label-width="8em" class="late-time">
            <el-input v-model="addForm.lateTime" placeholder="默认无限制" />分钟
          </el-form-item>
        </el-form>
      </div>
      <div class="middle">
        <div class="border-style" />
        <div>队列信息</div>
        <div class="border-style" />
      </div>
      <div class="box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :row-style="tableCellQueueStyle"
          style="width: 100%; height: auto"
          @row-click="rowQueueClick"
        >
          <el-table-column prop="cpu" label="名称" show-overflow-tooltip />
          <el-table-column
            prop="avaliable"
            label="可用资源"
            show-overflow-tooltip
          />
          <el-table-column
            prop="price"
            label="单价（元）"
            show-overflow-tooltip
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { ElMessage } from 'element-plus'
import {
  queuePersonList
} from '@/api/file'
export default {
  name: 'ApplicationPublic',
  props: ['iscpu', 'dirs', 'valid', 'isdir'],
  data() {
    const validateName = (rule, value, callback) => {
      const regExp = /^[a-z\d][a-z\d\-_]*[a-z\d]$/
      if (value === '') {
        callback(new Error('请输入作业名称'))
      } else {
        if (value.length < 4 || value.length > 15) {
          this.$emit('validChange', 0)
          callback(new Error('作业名称应为4-15个字符'))
        } else {
          if (regExp.test(value) === false) {
            this.$emit('validChange', 0)
            callback(new Error('作业名称仅可包含小写字母数字破折号(-)和下划线(_)且只能以字母数字作为开头结尾'))
          } else {
            this.$emit('validChange', 1)
            callback()
          }
        }
      }
    }
    const validateNum = (rule, value, callback) => {
      const regExp = /^[1-9]\d*$/
      if (value === null || value === '') {
        this.$emit('validChange', 1)
        callback()
      } else {
        if (regExp.test(value) === false) {
          this.$emit('validChange', 0)
          callback(new Error('请输入正确的最大运行时长，单位为分钟！'))
        } else {
          if (value.length > 7) {
            this.$emit('validChange', 0)
            callback(new Error('请输入合理的最大运行时长！'))
          } else {
            this.$emit('validChange', 1)
            callback()
          }
        }
      }
    }
    return {
      uId: 0,
      isLoading: true,
      currentRow: -1,
      loading: false,
      loadingDialog: false,
      dirFormVisible: false,
      filesData: [],
      tableData: [],
      data: [],
      path: '',
      queue: '',
      row: '',
      addForm: {
        jobName: '',
        dir: '',
        gpu: 0,
        tasks_per_node: 1,
        nodes: 1,
        lateTime: null
      },
      choiceType: 'dir',
      rules: {
        jobName: [{ required: true, trigger: 'blur', validator: validateName }],
        dir: [{ required: true, trigger: 'change', message: '请选择目录' }],
        tasks_per_node: [{ required: true, trigger: 'change', message: '请选择每节点核心数' }],
        nodes: [{ required: true, trigger: 'change', message: '请选择核心数' }],
        gpu: [{ required: true, trigger: 'change', message: '请选择GPU' }],
        lateTime: [{ required: true, trigger: 'blur', validator: validateNum }]
      }
    }
  },
  computed: {
    maxNodes() {
      if (this.queue) {
        const max = Math.floor(this.queue.quota / 56) // 56
        return max < 1 ? 1 : max
      }
      return 1
    }
  },
  watch: {
    addForm: {
      handler(newVal, oldVal) {
        this.$emit('formChange', this.addForm)
      },
      deep: true
    },
    dirs(n, o) {
      this.addForm.dir = n
    },
    valid(n, o) {
      if (n === 1) {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.$emit('validChange', 1)
          } else {
            this.$emit('validChange', 0)
          }
        })
      }
      if (n === 3) {
        this.$refs.addForm.resetFields()
      }
    }
  },
  mounted() {
    if (this.isdir === 1) {
      this.addForm.dir = '/public/home/' + store.getters.name
    }
    this.getData()
  },
  methods: {
    getData() {
      queuePersonList(localStorage.uId).then((response) => {
        this.tableData = response.data.content
        this.isLoading = false
        this.$emit('getData')
        if (this.tableData.length !== 0) {
          const lowestPriceItem = this.tableData.reduce((minItem, currentItem) => {
            return Number(currentItem.price) < Number(minItem.price) ? currentItem : minItem
          })
          this.queue = lowestPriceItem
          this.$emit('getData', this.isLoading, this.queue)
        } else {
          this.$emit('getData', this.isLoading, '')
        }
      })
    },
    // 选择目录 改变
    changDirs() {
      // this.choiceType = 'dir'
      // this.dirFormVisible = true
      this.$emit('changDirs')
    },
    // 选择队列
    rowQueueClick(row) {
      this.queue = row
      this.$emit('getData', false, row)
    },
    // 队列颜色改变
    tableCellQueueStyle(row) {
      if (this.queue === row.row) {
        return 'background-color:#e2e8f4;'
      } else {
        return 'background-color:#fff;'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.application-public{
  .late-time{
    ::v-deep .el-form-item__label:before{
      content: '' !important;
    }
    ::v-deep .el-input{
      width: 145px;
      .el-input__inner{
        width: 132px;
       }
    }
  }
  .left {
      margin-top: 20px;
      padding: 30px 0;
      background: rgba(245, 247, 250, 0.5);
      border: 1px solid #E8E8E8;
      min-width: 320px;
      width: 45%;
      position: relative;
      height: 100%;
      .el-form-item--small.el-form-item{
        margin-bottom: 40px;
      }
      .box{
        padding: 0 20px;
        .tips{
          // padding-left: 100px;
          color: rgb(147, 145, 143);
        }
      }
      .middle{
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .border-style{
          border-bottom: 1px solid #D8D8D8;
          width: 100px;
        }
      }
      .title {
      position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
    }
      ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
    }
}
</style>
